import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { StripeError } from '@stripe/stripe-js/types/stripe-js/stripe';
import { useMutation } from '@tanstack/react-query';
import {
  Box,
  Button,
  ChevronLeftIcon,
  Divider,
  HStack,
  Text,
} from 'native-base';
import React from 'react';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import EnvConfig from '../../utils/EnvConfig';

const stripePromise = loadStripe(EnvConfig.STRIPE_PUBLISHABLE_KEY); // Instantiate once only

const PaymentPage = () => {
  const navigate = useNavigate();
  const { orderId } = useParams<{ orderId: string }>();
  const [searchParams] = useSearchParams();
  const clientSecret: string | undefined = searchParams.get(
    'payment_intent_client_secret',
  );

  // No client secret is provided, the user probably reached this page via an invalid path
  // Redirect to the start of ordering flow
  if (!clientSecret) {
    navigate('/checkout', { replace: true });
    return null;
  }

  return (
    <Elements
      options={{
        clientSecret: clientSecret,
        appearance: {
          theme: 'stripe',
        },
      }}
      stripe={stripePromise}>
      <StripeForm
        onBack={() => {
          navigate(-1);
        }}
        // Stripe will redirect to the following url on payment success:
        // https://subdomain.order.wafflepos.com/orders/67648586-2e95-457e-8574-ba9a03995e45/summary
        // ?payment_intent=pi_3MHLyiEYxe7xsCph18r7F4ca
        // &payment_intent_client_secret=pi_3MHLyiEYxe7xsCph18r7F4ca_secret_lw2NoFNZ0iGoxfdg3dC1R2O4g
        // &redirect_status=succeeded
        returnUrl={`${window.location.origin}/orders/${orderId}/summary`}
      />
    </Elements>
  );
};

const StripeForm = ({
  onBack,
  returnUrl,
}: {
  onBack: () => void;
  returnUrl: string;
}) => {
  const stripe = useStripe();
  const elements = useElements();

  const canSubmitPayment: boolean = !!stripe && !!elements;

  const {
    mutate: handleSubmit,
    isPending: isHandleSubmitPending,
    error: handleSubmitError,
  } = useMutation({
    mutationFn: async (): Promise<void> => {
      if (!canSubmitPayment) {
        // Stripe.js has not yet loaded.
        // Make sure to disable form submission until Stripe.js has loaded.
        return;
      }

      const result: never | { error: StripeError } =
        await stripe.confirmPayment({
          elements,
          confirmParams: {
            // If payment is successful, Stripe will always redirect to the `return_url`
            return_url: returnUrl,
          },
        });

      if (result.error) {
        throw new Error(result.error.message);
      }
    },
  });

  // const errorMessage =
  //   isPaymentIntentError ||
  //   (!!paymentIntent && !!paymentIntent.last_payment_error) ||
  //   isHandleSubmitError
  //     ? 'An error occurred when processing your payment. You have not been charged, please try again.'
  //     : undefined;

  // if (isPaymentIntentPending) {
  //   return <WaffleLoaderComponent />;
  // }

  return (
    <Box
      minHeight={'100%'}
      width={'100%'}
      justifyContent={'space-between'}
      alignItems={'center'}>
      <Box flex={1} width={'100%'} maxWidth={600}>
        {/*Header*/}
        <HStack padding={2}>
          <Button
            variant={'ghost'}
            colorScheme={'onSurface'}
            leftIcon={<ChevronLeftIcon size={4} color={'text.400'} />}
            onPress={() => onBack()}>
            Review order
          </Button>
        </HStack>

        <Box
          margin={2}
          padding={4}
          borderRadius={'lg'}
          backgroundColor={'background.0'}>
          <Text variant={'subHeader'} marginBottom={2}>
            Payment
          </Text>
          <Divider marginBottom={4} />
          {!!handleSubmitError && (
            <Box
              marginY={2}
              padding={4}
              borderRadius={'lg'}
              backgroundColor={'error.100'}
              borderColor={'error.200'}
              borderWidth={1}>
              <Text color={'danger.800'}>
                {handleSubmitError.message ??
                  `An error occurred when processing your payment. You have not
                been charged, please try again.`}
              </Text>
            </Box>
          )}
          <PaymentElement
            options={{
              layout: {
                type: 'accordion',
              },
              paymentMethodOrder: ['apple_pay', 'google_pay'], // ApplePay and GooglePay options always come first
            }}
          />
        </Box>
      </Box>
      <>
        {/*Spacer for footer*/}
        <Box height={20} />
        {/*Footer*/}
        <Box
          position={'fixed'}
          bottom={0}
          height={20}
          width={'100%'}
          justifyContent={'center'}
          alignItems={'center'}
          backgroundColor={'background.0'}>
          <Box height={'100%'} width={'100%'} maxWidth={600} padding={4}>
            <Button
              flex={1}
              size={'lg'}
              isLoading={isHandleSubmitPending}
              disabled={!canSubmitPayment}
              onPress={() => handleSubmit()}>
              Submit
            </Button>
          </Box>
        </Box>
      </>
    </Box>
  );
};

export default PaymentPage;
